import {PaymentMethod} from '@wix/wixstores-graphql-schema-node';
import {ShippingMethodType} from '../../common/constants';
import {calcVisibleViolations} from './violations.utils';
import {CartModel} from '../models/Cart.model';
import {EstimatedTotalsModel} from '../models/EstimatedTotals.model';
import {ViolationModel} from '../models/Violation.model';

const BI_AMOUNT_MULTIPLIER = 100000;
export const toBiAmount = (amount: number) => Math.round(amount * BI_AMOUNT_MULTIPLIER);

export const getCatalogAppIds = (cartModel: CartModel): string => {
  return getMappedUniqueList(cartModel?.lineItems, ({catalogReference: {appId}}) => appId);
};

export const getItemTypes = (cartModel: CartModel): string => {
  return getMappedUniqueList(cartModel.lineItems, ({itemType}) => itemType);
};

function getMappedUniqueList<T>(arr: T[] | undefined, mapper: (t: T) => string | undefined | null): string {
  return getUniques((arr ?? /* istanbul ignore next */ []).map(mapper).filter(isDefined)).toString();
}

const getUniques = (arr: string[]): string[] => {
  return [...new Set([...arr])];
};

const isDefined = <T>(obj: T): boolean => {
  return !!obj;
};

export const getAdditionalFeesPrice = (estimatedTotals: EstimatedTotalsModel): number => {
  return toBiAmount(estimatedTotals?.priceSummary?.additionalFees?.convertedAmount ?? 0);
};

export const getValidations = (
  violations: ViolationModel[],
  supportDeliveryViolationOnCart: boolean
): string | undefined => {
  if (violations?.length > 0) {
    const top3violations = calcVisibleViolations(violations, supportDeliveryViolationOnCart);
    const biViolations = top3violations.map((violation) => {
      return {
        type: violation.severity,
        is_line_item: Boolean(violation.target?.lineItemTarget?.id),
        message: violation.description,
        ...(supportDeliveryViolationOnCart && {
          name: violation.target?.otherTarget?.name || violation.target?.lineItemTarget?.name,
        }),
      };
    });

    return JSON.stringify(biViolations);
  }

  return undefined;
};

export const stringifyPaymentMethods = (paymentMethods: PaymentMethod[]): string => {
  return paymentMethods.map((m) => m.name).join(',');
};

export const getNumberOfAdditionalFees = (estimatedTotals: EstimatedTotalsModel): number => {
  return estimatedTotals?.additionalFees?.length;
};

export const getItemsCount = (cartModel: CartModel): number => {
  return cartModel?.lineItems.reduce((count, lineItem) => count + lineItem.quantity, 0) || 0;
};

export const getShippingMethodType = (isPickupFlow: boolean, isNonShippableCart: boolean): ShippingMethodType => {
  const existingShippingMethodType = isPickupFlow ? ShippingMethodType.PICKUP : ShippingMethodType.SHIPPING;
  return isNonShippableCart ? ShippingMethodType.NONE : existingShippingMethodType;
};

export const getOriginalShippingMethod = (cartModel: CartModel, estimatedTotals: EstimatedTotalsModel): string => {
  const options = estimatedTotals.shippingInfo?.shippingRule?.shippingOptions || [];
  const selectedShippingRuleOption = options.find(
    (option) => option.id === estimatedTotals.shippingInfo.selectedCarrierServiceOptionCode
  );
  return selectedShippingRuleOption?.title ?? '';
};
