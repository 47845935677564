import {IShippingInfo} from '../../types/app.types';
import {ShippingRuleModel} from './ShippingRule.model';
import {ShippingInformation, CarrierErrors} from '@wix/ecom_current-cart';
import {getShippingRuleInfo} from './shippingRuleInfoImportedFromNodeGQL';

export class ShippingInfoModel {
  public status: string;
  public shippingRule: ShippingRuleModel;
  public canShipToDestination: boolean;
  public selectedCarrierServiceOptionCode: string;

  constructor(params: ShippingInfoModel) {
    this.status = params.status;
    this.shippingRule = params.shippingRule;
    this.canShipToDestination = params.canShipToDestination;
    this.selectedCarrierServiceOptionCode = params.selectedCarrierServiceOptionCode;
  }

  public static fromGQL(shippingInfo: IShippingInfo, selectedCarrierServiceOptionCode: string) {
    return new ShippingInfoModel({
      status: shippingInfo?.status ?? undefined,
      shippingRule: shippingInfo?.shippingRule ? ShippingRuleModel.fromGQL(shippingInfo?.shippingRule) : undefined,
      canShipToDestination: shippingInfo?.canShipToDestination ?? undefined,
      selectedCarrierServiceOptionCode: selectedCarrierServiceOptionCode ?? undefined,
    });
  }

  public static fromSDK(shippingInfo: ShippingInformation, carrierErrors: CarrierErrors) {
    const shippingRuleInfo = getShippingRuleInfo(shippingInfo, carrierErrors);

    return new ShippingInfoModel({
      status: shippingRuleInfo?.status ?? undefined,
      shippingRule: shippingRuleInfo?.shippingRule
        ? ShippingRuleModel.fromGQL(shippingRuleInfo.shippingRule)
        : undefined,
      canShipToDestination: shippingRuleInfo?.canShipToDestination ?? undefined,
      selectedCarrierServiceOptionCode: shippingInfo?.selectedCarrierServiceOption?.code,
    });
  }
}
